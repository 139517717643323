import React, { useEffect, useState } from "react";
import Styles from "./slider.module.css";
import ImageWithLoader from "../hooks/ImageWithLoader";

const Slider = () => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1000) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`${Styles.imageContainer} ${isHidden ? Styles.hidden : ""}`}>
      <div></div>
  
      <ImageWithLoader src="/images/slider/slider1.jpg" alt="ASP" className={Styles.image} />
    </div>
  );
};

export default Slider;
