import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Layout from "./components/layout/Layout";
import Nomatch from "./components/nomatch";
import DetailsPageLayout from "./components/layout/detailspageslayout";
import GraphicsDesign from "./components/generalpages/graphicsdesign";
import ProductDetails from "./components/products/productsdetails";
import SearchResults from "./components/products/searchresults";
import CategoryProductsDisplay from "./components/products/CategoryProducts";
import Checkout from "./components/products/Checkout";
import AllBlogPosts from "./components/generalpages/Blogs/allblogposts";
import BlogPost from "./components/generalpages/Blogs/BlogPost";
import ContactUS from "./components/generalpages/contactus";
import FAQPage from "./components/generalpages/faq";

function AppRouter() {

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const loadBlogs = async () => {
      const blogFiles = [
        "building-websites-and-webapps.mdx",
        "designing-content-for-business.mdx",
        "digital-marketing-services.mdx",
        "using-e-cards-for-sharing-events.mdx",
        "integrating-ai-with-digital-graphics.mdx",
        "planning-e-marketing-services.mdx",
      ];
      const loadedBlogs = await Promise.all(
        blogFiles.map(async (file) => {
          const blog = await import(`./components/generalpages/Blogs/${file}`);
          return { ...blog, slug: file.replace(".mdx", "") };
        })
      );
      setBlogs(loadedBlogs);
    };

    loadBlogs();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Nomatch />} />
        </Route>
        <Route path="/" element={<DetailsPageLayout />}>
          <Route path="/graphicsdesign" element={<GraphicsDesign />} />
          <Route path="/allblogposts" element={<AllBlogPosts />} />
          <Route path="/blog/:slug" element={<BlogPost blogs={blogs} />} />
          <Route path="/contactus" element={<ContactUS />} />
          <Route path="/faqs" element={<FAQPage />} />
          <Route path="/:category/:productLink" element={<ProductDetails />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route
            path="/category/:category"
            element={<CategoryProductsDisplay />}
          />
          <Route path="/checkout" element={<Checkout />} />
        </Route>
      </Routes>
    </>
  );
}

export default AppRouter;
