import React from "react";
import { useParams } from "react-router-dom";
import blogData from "./blogData.json";
import styles from "./BlogPost.module.css";
import ImageWithLoader from "../../hooks/ImageWithLoader";

const BlogPost = () => {
  const { slug } = useParams();
  const blog = blogData.find((b) => b.slug === slug);

  if (!blog) return <h1>Blog Not Found</h1>;

  return (
    <div className={styles.blogPostContainer}>
      <h1>{blog.title}</h1>
      <p className={styles.category}>{blog.category}</p>
      <p className={styles.author}>
        By {blog.author} | {blog.date}
      </p>
      <ImageWithLoader
        src={blog.image}
        alt={blog.title}
        className={styles.featuredImage}
      />

      {/* Render content with basic markdown support */}
      <div
        dangerouslySetInnerHTML={{
          __html: blog.content.replace(/\n/g, "<br/>"),
        }}
      />
    </div>
  );
};

export default BlogPost;
