const SignageData = [
  {
    id: 251,
    name: "Banners",
    link: "sinage/banners",
    category: "Adverts Signage",
    categoryimage: "/images/categories/banners.jpg",
    categorydescription:
      "High-quality banners for outdoor and indoor advertising.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "3000",
    quantity: 1,
    extradetails: "Available in PVC, mesh, and fabric options.",
    leadtimedelivery: 3,
  },
  {
    id: 252,
    name: "Stickers",
    link: "sinage/stickers",
    category: "Adverts Signage",
    categoryimage: "/images/categories/stickers.jpg",
    categorydescription:
      "Custom stickers for branding, packaging, and advertising.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "500",
    quantity: 50,
    extradetails: "Available in paper, vinyl, and clear materials.",
    leadtimedelivery: 2,
  },
  {
    id: 253,
    name: "Roll-Up Banners",
    link: "sinage/roll-up-banners",
    category: "Adverts Signage",
    categoryimage: "/images/categories/roll-up-banners.jpg",
    categorydescription: "Portable roll-up banners for events and trade shows.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "7500",
    quantity: 1,
    extradetails: "Comes with an aluminum stand and carry case.",
    leadtimedelivery: 4,
  },
  {
    id: 254,
    name: "Tear Drop Banners",
    link: "sinage/tear-drop-banners",
    category: "Adverts Signage",
    categoryimage: "/images/categories/tear-drop-banners.jpg",
    categorydescription:
      "Eye-catching tear drop banners for outdoor advertising.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "8500",
    quantity: 1,
    extradetails: "Comes with a flexible pole and base for easy setup.",
    leadtimedelivery: 5,
  },
  {
    id: 255,
    name: "Back-Drop Banners",
    link: "sinage/back-drop-banners",
    category: "Adverts Signage",
    categoryimage: "/images/categories/back-drop-banners.jpg",
    categorydescription: "Large backdrop banners for events and conferences.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "15000",
    quantity: 1,
    extradetails: "Custom sizes available with frame setup.",
    leadtimedelivery: 7,
  },
  {
    id: 256,
    name: "Billboards",
    link: "sinage/billboards",
    category: "Adverts Signage",
    categoryimage: "/images/categories/billboards.jpg",
    categorydescription: "Outdoor billboards for large-scale advertising.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "250000",
    quantity: 1,
    extradetails: "Available in static and digital formats.",
    leadtimedelivery: 14,
  },
  {
    id: 257,
    name: "Vehicle Branding",
    link: "sinage/vehicle-branding",
    category: "Adverts Signage",
    categoryimage: "/images/categories/vehicle-branding.jpg",
    categorydescription: "Custom vehicle branding for mobile advertising.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "30000",
    quantity: 1,
    extradetails: "Full and partial wraps available.",
    leadtimedelivery: 7,
  },
  {
    id: 258,
    name: "One-Way Vision Stickers",
    link: "sinage/one-way-vision-stickers",
    category: "Adverts Signage",
    categoryimage: "/images/categories/one-way-vision-stickers.jpg",
    categorydescription:
      "Perforated window stickers for advertising and privacy.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "3500",
    quantity: 1,
    extradetails: "Ideal for glass windows and doors.",
    leadtimedelivery: 3,
  },
  {
    id: 259,
    name: "Neon Signs",
    link: "sinage/neon-signs",
    category: "Adverts Signage",
    categoryimage: "/images/categories/neon-signs.jpg",
    categorydescription: "Custom neon signs for businesses and events.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "20000",
    quantity: 1,
    extradetails: "Available in various colors and designs.",
    leadtimedelivery: 10,
  },
  {
    id: 260,
    name: "3D Signage",
    link: "sinage/3d-signage",
    category: "Adverts Signage",
    categoryimage: "/images/categories/3d-signage.jpg",
    categorydescription: "Custom 3D signs for storefronts and branding.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "50000",
    quantity: 1,
    extradetails: "Available in acrylic, metal, and LED-lit designs.",
    leadtimedelivery: 14,
  },
  {
    id: 261,
    name: "Directional & Safety Signs",
    link: "sinage/directional-safety-signs",
    category: "Adverts Signage",
    categoryimage: "/images/categories/directional-safety-signs.jpg",
    categorydescription: "Custom safety and directional signage.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "5000",
    quantity: 1,
    extradetails: "Ideal for offices, factories, and public spaces.",
    leadtimedelivery: 5,
  },
  {
    id: 262,
    name: "Light Boxes",
    link: "sinage/light-boxes",
    category: "Adverts Signage",
    categoryimage: "/images/categories/light-boxes.jpg",
    categorydescription: "Illuminated light box signs for businesses.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "30000",
    quantity: 1,
    extradetails: "Available in acrylic and metal finishes.",
    leadtimedelivery: 7,
  },
];

export default SignageData;
