const FeaturedProduct = [
  {
    title: "Custom Hoodies",
    fImage: "/images/rightmenuimages/image1.jpg",
    link: "/officestationery/envelopes",
  },
  {
    title: "Business Cards",
    fImage: "/images/rightmenuimages/image2.jpg",
    link: "/officestationery/envelopes",
  },
  {
    title: "Custom Branded Tshirts",
    fImage: "/images/rightmenuimages/image3.jpg",
    link: "/officestationery/envelopes",
  },
  {
    title: "Sublimation Mugs",
    fImage: "/images/rightmenuimages/image4.jpg",
    link: "/officestationery/envelopes",
  },
  {
    title: "Water Bottles",
    fImage: "/images/rightmenuimages/image5.jpg",
    link: "/officestationery/envelopes",
  },
  {
    title: "Thermoflasks",
    fImage: "/images/rightmenuimages/image6.jpg",
    link: "/officestationery/envelopes",
  },
  {
    title: "Flyers",
    fImage: "/images/rightmenuimages/image7.jpg",
    link: "/officestationery/envelopes",
  },
  {
    title: "Brochures",
    fImage: "/images/rightmenuimages/image8.jpg",
    link: "/officestationery/envelopes",
  },
];
export default FeaturedProduct;
