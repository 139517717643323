import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ImageWithLoader from "./ImageWithLoader";

const CarouselItem = ({ data }) => {
  const [focusedIndex, setFocusedIndex] = useState(null);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  return (
    <div>
      <Carousel responsive={responsive}>
        {data.map((item, index) => (
          <ImageWithLoader
            src={item.name}
            alt="Aspire Graphics"
            key={index}
            style={{
              width: "60%",
              cursor: "pointer",
              transition: "transform 0.3s ease-in-out",
              transform: focusedIndex === index ? "scale(1.5)" : "scale(1)",
            }}
            onMouseEnter={() => setFocusedIndex(index)}
            onMouseLeave={() => setFocusedIndex(null)}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselItem;
