import React from "react";
import Styles from "./styles.module.css";
// import Featured from "../../images/webdesign.png";
import CarouselItem from "../hooks/carouselitem";
const GraphicsDesign = () => {
  return (
    <div className={Styles.container}>
      
      <div className={Styles.columnB}>
        <div style={{ margin: "1rem" }}>
          <h2>Graphics Design Services</h2>
          <p>
            We bring creativity to life with high-quality, innovative, and
            visually striking graphic design solutions. Whether you need
            branding, marketing materials, or custom design work, our team of
            skilled designers is here to help you communicate your message
            effectively.
          </p>
          <p>
            Whether it's a Website Landing pages or E-commerce platforms, Or
            mobile App to drive and support your business, or Product Mock-up
            designs we can help you validate your goals.
          </p>
          <button className={Styles.moreDataButton}>Request Service</button>
          <hr />
        </div>
        <div style={{ margin: "1rem" }}>
          <h2>Logo & Brand Identity Design</h2>
          <p>
            We bring creativity to life with high-quality, innovative, and
            visually striking graphic design solutions. Whether you need
            branding, marketing materials, or custom design work, our team of
            skilled designers is here to help you communicate your message
            effectively.
          </p>
          <CarouselItem data={DataImages} />
          <hr />
        </div>
        <div style={{ margin: "1rem" }}>
          <h2>2. Marketing & Promotional Materials</h2>
          <p>
            We bring creativity to life with high-quality, innovative, and
            visually striking graphic design solutions. Whether you need
            branding, marketing materials, or custom design work, our team of
            skilled designers is here to help you communicate your message
            effectively.
          </p>
        </div>
        </div>
    </div>
  );
};

export default GraphicsDesign;

const DataImages = [
  {
    id: "0001",
    name: "images/logos/zurilogo.jpg",
  },
  {
    id: "0002",
    name: "images/logos/aspiregraphics.jpg",
  },
  {
    id: "0003",
    name: "images/logos/curdafrica.jpg",
  },
  {
    id: "0004",
    name: "images/logos/kpdclogo.jpg",
  },
];
