import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import styles from "./Cart.module.css";
import ImageWithLoader from "../hooks/ImageWithLoader";

const Cart = ({ cartItems, setCart, onClose }) => {
  const modalRef = useRef(null);
  const navigate = useNavigate(); // Initialize navigation

  const handleRemove = (id) => {
    const updatedCart = cartItems.filter((item) => item.id !== id);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const totalCartPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  // Navigate to Checkout Page
  const handleCheckout = () => {
    navigate("/checkout", { state: { cartItems, totalCartPrice } }); // Pass cart data via state
  };

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className={styles.cartModal}>
      <div className={styles.cartContainer} ref={modalRef}>
        <button className={styles.closeButton} onClick={onClose}>✖ Exit Cart</button>
        <h2>Your Cart</h2>

        {cartItems.length === 0 ? (
          <p>Cart is empty</p>
        ) : (
          <table className={styles.cartTable}>
            <thead>
              <tr>
                <th>Item</th>
                <th>Image</th>
                <th>Quantity</th>
                <th>Price (Ksh)</th>
                <th>Total (Ksh)</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>
                  <ImageWithLoader  src={item.productImages[0]} alt={item.name} className={styles.cartImage} />
                   
                  </td>
                  <td>{item.quantity}</td>
                  <td>{item.price}</td>
                  <td>{item.price * item.quantity}</td>
                  <td>
                    <button className={styles.removeButton} onClick={() => handleRemove(item.id)}>
                      ❌
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <h3>Total: Ksh {totalCartPrice}</h3>
        <button className={styles.checkoutButton} onClick={handleCheckout}>
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default Cart;
