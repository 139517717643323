import React, { useState } from "react";
import Styles from "./Navbar.module.css";
import Logo from "../../images/logo.png";
import WhatsApp from "../../images/whatsapp.png";
import Phone from "../../images/phone.png";
import { Link, useNavigate } from "react-router-dom";
import Menus from "../menus/menus";
import ImageWithLoader from "../hooks/ImageWithLoader";

// Import product data
import CreativeDesignsData from "../alldata/creativedata";
import OfficeStationeryData from "../alldata/officestationerydata";
import PromotionalItemsData from "../alldata/promotionalitemsdata";
import SignageData from "../alldata/signagedata";

const NavBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const navigate = useNavigate();

  // Combine all product data into one array
  const allProducts = [
    ...CreativeDesignsData,
    ...OfficeStationeryData,
    ...PromotionalItemsData,
    ...SignageData,
  ];

  // Extract unique categories from all products
  const uniqueCategories = [
    ...new Set(allProducts.map((product) => product.category)),
  ];

  // Filter products based on the search query and category
  const filteredProducts = allProducts.filter((product) => {
    const matchesSearch =
      (product.name && product.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (product.description && product.description.toLowerCase().includes(searchQuery.toLowerCase()));
    const matchesCategory =
      categoryFilter === "" || product.category === categoryFilter;

    return matchesSearch && matchesCategory;
  });

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // // Handle category filter change
  // const handleCategoryChange = (event) => {
  //   setCategoryFilter(event.target.value);
  // };

  // Redirect to the search results page
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate("/search-results", { state: { results: filteredProducts } });
    
    // Clear the search query after submitting
    setSearchQuery("");
  };

  // Redirect to the category display page when a category is selected
  const handleCategoryRedirect = (event) => {
    const selectedCategory = event.target.value;
    setCategoryFilter(selectedCategory);
    if (selectedCategory) {
      navigate(`/category/${selectedCategory.toLowerCase().replace(/\s+/g, '-')}`); // Convert category to lowercase and replace spaces with dashes
    } else {
      navigate("/"); // Optionally redirect to the homepage
    }
  };

  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.subcontainer}>
        <div className={Styles.leftContainer}>
          <div className={Styles.imageContainer}>
            <Link to="/">
            <ImageWithLoader src={Logo}
                alt="Aspire Graphics Ltd"
                className={Styles.image} />
              
            </Link>
          </div>
        </div>
        <div className={Styles.centerContainer}>
          <form className={Styles.formSearch} onSubmit={handleSearchSubmit}>
            <input
              className={Styles.inputSearch}
              type="search"
              placeholder="Search : Business Cards, Brochures, Flyer..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <select
              className={Styles.button}
              value={categoryFilter}
              onChange={handleCategoryRedirect} // Redirect to category page
            >
              <option value="">All Categories</option>
              {uniqueCategories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
           
          </form>
        </div>
        <div className={Styles.rightContainer}>
          <div className={Styles.imageContainerWhatsApp}>
          <ImageWithLoader src={Phone}
              alt="Aspire Graphics Ltd"
              className={Styles.imageWhatsApp} />
           
          </div>
          <p style={{ padding: "0rem 1rem", cursor: "pointer" }}>
            <a href="tel:+254722513085">+254-722-513-085</a>
          </p>
          <div className={Styles.imageContainerWhatsApp}>
          <ImageWithLoader src={WhatsApp}
              alt="Aspire Graphics Ltd"
              className={Styles.imageWhatsApp}  />
            
          </div>
        </div>
      </div>
      <Menus />
    </div>
  );
};

export default NavBar;
