import React from "react";
import styles from "./OrderConfirmation.module.css";

const OrderConfirmation = ({ orderNumber }) => (
  <div className={styles.confirmationContainer}>
    <h2>🎉 Thank You!</h2>
    <p>Your order has been placed successfully.</p>
    <h3>Order No: {orderNumber}</h3>
  </div>
);

export default OrderConfirmation;
