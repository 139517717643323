const CreativeDesignsData = [
  {
    id: 101,
    name: "Logos",
    link: "creativedesigns/logos",
    category: "Brand Identity",
    categoryimage: "/images/categories/logos.jpg",
    categorydescription:
      "Custom-designed logos to represent your brand identity effectively.",
    productImage: "/images/logos/aspiregraphics.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "High-resolution vector files, multiple design concepts, and revisions included.",
    leadtimedelivery: 3,
  },
  {
    id: 102,
    name: "Flyers & Posters",
    link: "creativedesigns/flyers-posters",
    category: "Creative Designs",
    categoryimage: "/images/categories/flyers-posters.jpg",
    categorydescription:
      "Engaging flyers and posters to effectively market your brand, events, or promotions.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "Printed or digital formats available, customized to your branding.",
    leadtimedelivery: 3,
  },
  {
    id: 103,
    name: "Info-Graphics",
    link: "creativedesigns/info-graphics",
    category: "Creative Designs",
    categoryimage: "/images/categories/info-graphics.jpg",
    categorydescription:
      "Visually compelling infographics to communicate complex information clearly.",
    productImages: [],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "Tailored to your brand, suitable for presentations, reports, and social media.",
    leadtimedelivery: 3,
  },
  {
    id: 104,
    name: "Business Reports",
    link: "creativedesigns/business-reports",
    category: "Creative Designs",
    categoryimage: "/images/categories/business-reports.jpg",
    categorydescription:
      "Professional business reports with structured layouts and engaging visuals.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "Formatted reports for corporate use, including data visualization and branding.",
    leadtimedelivery: 3,
  },
  {
    id: 105,
    name: "Websites",
    link: "creativedesigns/websites",
    category: "Creative Designs",
    categoryimage: "/images/categories/websites.jpg",
    categorydescription:
      "Custom-designed websites tailored to your brand and business needs.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "Responsive design, SEO optimization, and user-friendly interfaces.",
    leadtimedelivery: 3,
  },
  {
    id: 106,
    name: "Mobile Apps",
    link: "creativedesigns/mobile-apps",
    category: "Creative Designs",
    categoryimage: "/images/categories/mobile-apps.jpg",
    categorydescription:
      "Innovative mobile applications for Android and iOS platforms.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "Custom features, user-friendly UI/UX, and seamless performance.",
    leadtimedelivery: 3,
  },
  {
    id: 107,
    name: "Brochures",
    link: "creativedesigns/brochures",
    category: "Creative Designs",
    categoryimage: "/images/categories/brochures.jpg",
    categorydescription:
      "Beautifully designed brochures to showcase your products and services.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails: "Available in bi-fold, tri-fold, and custom formats.",
    leadtimedelivery: 3,
  },
  {
    id: 108,
    name: "Social Media Graphics",
    link: "creative-designs/social-media-graphics",
    category: "Creative Designs",
    categoryimage: "/images/categories/social-media-graphics.jpg",
    categorydescription:
      "Eye-catching social media visuals to enhance your brand presence.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "Custom posts, banners, and story templates for Facebook, Instagram, and more.",
    leadtimedelivery: 3,
  },
  {
    id: 109,
    name: "Corporate Branding",
    link: "creative-designs/corporate-branding",
    category: "Brand Identity",
    categoryimage: "/images/categories/corporate-branding.jpg",
    categorydescription:
      "Complete corporate branding solutions, including stationery and marketing materials.",
      productImage: "/images/businesscards/businesscard8.jpg",
      productImages: [
        {
          original: "/images/logos/aspiregraphics.jpg",
          thumbnail: "/images/logos/aspiregraphics.jpg",
        },
        {
          original: "/images/logos/curdafrica.jpg",
          thumbnail: "/images/logos/curdafrica.jpg",
        },
        {
          original: "/images/logos/kpdclogo.jpg",
          thumbnail: "/images/logos/kpdclogo.jpg",
        },
        {
          original: "/images/logos/zurilogo.jpg",
          thumbnail: "/images/logos/zurilogo.jpg",
        },
      ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails: "Includes business cards, letterheads, and company profiles.",
    leadtimedelivery: 3,
  },
  {
    id: 110,
    name: "Packaging Design",
    link: "creative-designs/packaging-design",
    category: "Creative Designs",
    categoryimage: "/images/categories/packaging-design.jpg",
    categorydescription:
      "Innovative packaging designs to make your products stand out.",
      productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "Custom product packaging with high-quality printing options.",
    leadtimedelivery: 3,
  },
  {
    id: 111,
    name: "Book Covers",
    link: "creative-designs/book-covers",
    category: "Creative Designs",
    categoryimage: "/images/categories/book-covers.jpg",
    categorydescription:
      "Stunning book cover designs for authors and publishers.",
      productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails: "Custom covers for ebooks and printed books.",
    leadtimedelivery: 3,
  },
  {
    id: 112,
    name: "Magazine Layouts",
    link: "creative-designs/magazine-layouts",
    category: "Creative Designs",
    categoryimage: "/images/categories/magazine-layouts.jpg",
    categorydescription:
      "Professional magazine layouts for print and digital publications.",
      productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "Visually appealing magazine pages with engaging typography and graphics.",
    leadtimedelivery: 3,
  },
  {
    id: 113,
    name: "Presentation Designs",
    link: "creative-designs/presentation-designs",
    category: "Creative Designs",
    categoryimage: "/images/categories/presentation-designs.jpg",
    categorydescription:
      "Visually appealing presentation slides for business, education, and marketing.",
      productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails:
      "Custom PowerPoint and Google Slides templates with infographics.",
    leadtimedelivery: 3,
  },
];

export default CreativeDesignsData;
