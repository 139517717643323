import { useState, useEffect } from "react";
import Footer from "../footer/footer";
import NavBar from "../navbar/navbar";
import { Link, Outlet } from "react-router-dom";
import Styles from "./styles.module.css";
import CreativeDesignsData from "../alldata/creativedata";
import OfficeStationeryData from "../alldata/officestationerydata";
import PromotionalItemsData from "../alldata/promotionalitemsdata";
import SignageData from "../alldata/signagedata";
import ImageWithLoader from "../hooks/ImageWithLoader";

const Rightmenuimages = [
  "/images/rightmenuimages/image1.jpg",
  "/images/rightmenuimages/image2.jpg",
  "/images/rightmenuimages/image3.jpg",
  "/images/rightmenuimages/image4.jpg",
  "/images/rightmenuimages/image5.jpg",
  "/images/rightmenuimages/image6.jpg",
  "/images/rightmenuimages/image7.jpg",
  "/images/rightmenuimages/image8.jpg",
];

const DetailsPageLayout = () => {
  const [randomImage, setRandomImage] = useState(Rightmenuimages[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * Rightmenuimages.length);
      setRandomImage(Rightmenuimages[randomIndex]);
    }, 10000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div>
      <NavBar />
      <div className={Styles.container}>
        <div className={Styles.columnA}>
          <div className={Styles.imageContainer}>
          <ImageWithLoader src={randomImage} alt="Aspire Graphics Ltd" className={Styles.image} />
           
          </div>
          <div>
            <div className={Styles.card}>
              <h4>Office Stationery</h4>
              {OfficeStationeryData.map((item) => (
                <Link key={item.id} to={item.link}>
                  <li>{item.name}</li>
                </Link>
              ))}
            </div>
            <div className={Styles.card}>
              <h4>Creative Designs</h4>
              {CreativeDesignsData.map((item) => (
                <Link key={item.id} to={item.link}>
                  <li>{item.name}</li>
                </Link>
              ))}
            </div>
            <div className={Styles.card}>
              <h4>Promotional Items</h4>
              {PromotionalItemsData.map((item) => (
                <Link key={item.id} to={item.link}>
                  <li>{item.name}</li>
                </Link>
              ))}
            </div>
            <div className={Styles.card}>
              <h4>Adverts & Signage</h4>
              {SignageData.map((item) => (
                <Link key={item.id} to={item.link}>
                  <li>{item.name}</li>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className={Styles.columnB}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DetailsPageLayout;
