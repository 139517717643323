import React, { useState } from "react";
// import { GoogleLogin } from "@react-oauth/google";

import styles from "./UserRegistration.module.css";

const UserRegistration = ({ onNext }) => {
  const [userDetails, setUserDetails] = useState({ name: "", email: "", phone: "", address: "", password: "" });

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleManualRegister = () => {
    if (userDetails.name && userDetails.email && userDetails.phone && userDetails.address && userDetails.password) {
      onNext(userDetails);
    } else {
      alert("Please fill all fields.");
    }
  };

  // const handleGoogleSuccess = (response) => {
  //   console.log(response);
  //   onNext({ name: "Google User", email: response.profileObj.email });
  // };

  // const handleFacebookSuccess = (response) => {
  //   console.log(response);
  //   onNext({ name: response.name, email: response.email });
  // };

  return (
    <div className={styles.registrationContainer}>
      <h2>Register / Sign In</h2>
      <input type="text" name="name" placeholder="Full Name" onChange={handleChange} />
      <input type="email" name="email" placeholder="Email" onChange={handleChange} />
      <input type="text" name="phone" placeholder="Phone Number" onChange={handleChange} />
      <input type="text" name="address" placeholder="Delivery Address" onChange={handleChange} />
      <input type="password" name="password" placeholder="Password" onChange={handleChange} />
      <button className={styles.registerButton} onClick={handleManualRegister}>Register</button>

      <h3>Or Sign in With</h3>
      {/* <GoogleLogin onSuccess={handleGoogleSuccess} onError={() => alert("Google Login Failed")} /> */}
      {/* <FacebookLogin appId="YOUR_FACEBOOK_APP_ID" autoLoad={false} fields="name,email,picture" callback={handleFacebookSuccess} /> */}
    </div>
  );
};

export default UserRegistration;
