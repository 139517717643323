const MenuData = [
  {
    id: "000",
    name: "Home",
    link: "/",
  },
  {
    id: "001",
    name: "Graphics Design",
    link: "/graphicsdesign",
  },
  {
    id: "002",
    name: "Brand Identity",
    link: "/category/brand-identity",
  },
  {
    id: "003",
    name: "Office Stationery",
    link: "/category/office-stationery",
  },
 
  {
    id: "004",
    name: "Promotional Items",
    link: "/category/promotional-items",
  },
  {
    id: "005",
    name: "Adverts & Signage",
    link: "/category/adverts-signage",
  },
  {
    id: "006",
    name: "Blog",
    link: "/allblogposts",
  },
  {
    id: "007",
    name: "FAQs",
    link: "/faqs",
  },
  {
    id: "008",
    name: "Contacts",
    link: "/contactus",
  },
];

export default MenuData;
