import React from "react";
import Styles from "./creativedesigns.module.css";
import Creative from "../../images/creativeslide.png";
import ImageWithLoader from "../hooks/ImageWithLoader";

const CreativeDesign = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.imageContainer}>
        <ImageWithLoader src={Creative} alt="ASP" className={Styles.image} />
      </div>
      <div className={Styles.moreDataContainer}>
        <button className={Styles.moreDataButton}>Request Service</button>
      </div>
    </div>
  );
};

export default CreativeDesign;
