import { useState, useEffect } from "react";
import Styles from "./brandidentity.module.css";
import CreativeDesignsData from "../../alldata/creativedata";
import { Link } from "react-router-dom";
import HomeProductsListing from "../../hooks/homeproductlisting";
import FeaturedProduct from "../../alldata/featuredproducts";
import ImageWithLoader from "../../hooks/ImageWithLoader";


const  BrandIdentity = () => {
  const [randomProduct, setRandomProduct] = useState(null);
  const [shuffledData, setShuffledData] = useState([]);

  const updateRandomProduct = () => {
    const randomIndex = Math.floor(Math.random() * FeaturedProduct.length);
    setRandomProduct(FeaturedProduct[randomIndex]);
  };

  const updateShuffledData = () => {
    const shuffled = [...CreativeDesignsData]
      .sort(() => 0.5 - Math.random())
      .slice(0, 4);
    setShuffledData(shuffled);
  };

  useEffect(() => {
    updateRandomProduct();
    updateShuffledData();

    const interval = setInterval(() => {
      updateRandomProduct();
      updateShuffledData();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.sectionContainer}>
        <div className={Styles.columnLeft}>
          {randomProduct && (
            <Link to={randomProduct.link}>
              <div className={Styles.featuredTitle}>
                <p>{randomProduct.title}</p>
              </div>
              <div className={Styles.imageContainer}>
              <ImageWithLoader src={randomProduct.fImage}
                  alt="Aspire Graphics Ltd"
                  className={Styles.imageFeatured} />
               
              </div>
            </Link>
          )}
          <div className={Styles.placeOrder}>
            <p>Place Order</p>
          </div>
        </div>
        <div className={Styles.columnRight}>
          <HomeProductsListing
            allProducts={CreativeDesignsData}
            ListingTitle="Brand Identity"
          />
          <div className={Styles.textLinks}>
            {CreativeDesignsData.sort((a, b) => a - b).map((link) => (
              <Link to={`/${link.link}`} key={link.id}>
                <li>{link.name} |</li>
              </Link>
            ))}

            <button className={Styles.moreDataButton}>More items</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandIdentity;
