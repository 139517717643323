const PromotionalItemsData = [
  {
    id: 201,
    name: "T-Shirts",
    link: "promotionalitems/t-shirts",
    category: "Promotional Items",
    categoryimage: "/images/categories/t-shirts.jpg",
    categorydescription: "Custom-branded T-shirts for promotional and corporate branding.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "1000",
    quantity: 1,
    extradetails: "Available in cotton, polyester, and blended fabric.",
    leadtimedelivery: 5
  },
  {
    id: 202,
    name: "Hoodies",
    link: "promotionalitems/hoodies",
    category: "Promotional Items",
    categoryimage: "promotionalitems/images/categories/hoodies.jpg",
    categorydescription: "High-quality branded hoodies for promotional use.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "2500",
    quantity: 1,
    extradetails: "Available in pullover and zip-up styles.",
    leadtimedelivery: 7
  },
  {
    id: 203,
    name: "Tote Bags",
    link: "promotionalitems/tote-bags",
    category: "Promotional Items",
    categoryimage: "/images/categories/tote-bags.jpg",
    categorydescription: "Eco-friendly tote bags for branding and giveaways.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "700",
    quantity: 1,
    extradetails: "Made from cotton, jute, or canvas.",
    leadtimedelivery: 5
  },
  {
    id: 204,
    name: "Pens",
    link: "promotionalitems/pens",
    category: "Promotional Items",
    categoryimage: "/images/categories/pens.jpg",
    categorydescription: "Custom-branded pens for corporate branding.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "50",
    quantity: 100,
    extradetails: "Available in plastic, metal, and executive styles.",
    leadtimedelivery: 3
  },
  {
    id: 205,
    name: "Mugs",
    link: "promotionalitems/mugs",
    category: "Promotional Items",
    categoryimage: "/images/categories/mugs.jpg",
    categorydescription: "Branded ceramic and travel mugs for giveaways.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails: "Available in ceramic, glass, and stainless steel.",
    leadtimedelivery: 4
  },
  {
    id: 206,
    name: "Drinkware",
    link: "promotionalitems/drinkware",
    category: "Promotional Items",
    categoryimage: "/images/categories/drinkware.jpg",
    categorydescription: "Branded drinkware, including water bottles and tumblers.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "1000",
    quantity: 1,
    extradetails: "Available in stainless steel, plastic, and glass.",
    leadtimedelivery: 5
  },
  {
    id: 207,
    name: "Caps",
    link: "promotionalitems/caps",
    category: "Promotional Items",
    categoryimage: "/images/categories/caps.jpg",
    categorydescription: "Branded caps and hats for corporate giveaways.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "800",
    quantity: 1,
    extradetails: "Available in cotton, polyester, and mesh-back styles.",
    leadtimedelivery: 5
  },
  {
    id: 208,
    name: "Key Holders",
    link: "promotionalitems/key-holders",
    category: "Promotional Items",
    categoryimage: "/images/categories/key-holders.jpg",
    categorydescription: "Custom-branded key holders for promotional giveaways.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "300",
    quantity: 1,
    extradetails: "Available in plastic, leather, and metal finishes.",
    leadtimedelivery: 3
  },
  {
    id: 209,
    name: "Umbrellas",
    link: "promotionalitems/umbrellas",
    category: "Promotional Items",
    categoryimage: "/images/categories/umbrellas.jpg",
    categorydescription: "Branded umbrellas for corporate and event promotions.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "2000",
    quantity: 1,
    extradetails: "Available in compact, golf, and automatic open styles.",
    leadtimedelivery: 6
  },
  {
    id: 210,
    name: "Wristbands",
    link: "promotionalitems/wristbands",
    category: "Promotional Items",
    categoryimage: "/images/categories/wristbands.jpg",
    categorydescription: "Custom silicone and fabric wristbands for promotions.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "150",
    quantity: 100,
    extradetails: "Printed or embossed with company logos and slogans.",
    leadtimedelivery: 4
  },
  {
    id: 211,
    name: "USB Flash Drives",
    link: "promotionalitems/usb-flash-drives",
    category: "Promotional Items",
    categoryimage: "/images/categories/usb-flash-drives.jpg",
    categorydescription: "Custom-branded USB drives for promotional campaigns.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "1500",
    quantity: 1,
    extradetails: "Available in 4GB, 8GB, 16GB, and 32GB capacities.",
    leadtimedelivery: 5
  },
  {
    id: 212,
    name: "Lanyards",
    link: "promotionalitems/lanyards",
    category: "Promotional Items",
    categoryimage: "/images/categories/lanyards.jpg",
    categorydescription: "Branded lanyards for corporate and event use.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "200",
    quantity: 100,
    extradetails: "Available in polyester, nylon, and woven fabric.",
    leadtimedelivery: 3
  }
];

export default PromotionalItemsData;
