import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import styles from './SearchResults.module.css'; // Import the CSS module

const SearchResults = () => {
  const location = useLocation();
  const { results } = location.state || { results: [] };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted or when results change
    window.scrollTo(0, 0);
  }, [results]); // Dependency on results to re-trigger the scroll effect when results change

  if (results.length === 0) {
    return <h2>No products found</h2>;
  }

  return (
    <div className={styles.searchResultsContainer}>
      <h1 className={styles.searchResultsTitle}>Search Results</h1>
      <div>
        {results.map((product) => (
          <Link
            to={`/${product.link}`} // Update the link to the product details page
            key={product.id}
            className={styles.productCardLink}
          >
            <div className={styles.productCard}>
              <h1 className={styles.productName}>{product.name}</h1>
              <p className={styles.productDescription}>{product.categorydescription}</p>
              <p className={styles.productPrice}>Price: Ksh {product.price}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SearchResults;
