import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import CategoryProductsStyles from "./CategoryProducts.module.css";
import ImageWithLoader from "./ImageWithLoader";

// Function to shuffle array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const HomeProductsListing = ({allProducts,ListingTitle}) => {
  const { category } = useParams();
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (allProducts) {
      const shuffledProducts = shuffleArray([...allProducts]);
      setFilteredProducts(shuffledProducts);
    } else {
      setFilteredProducts([]);
    }
  }, [category]);

  if (filteredProducts.length === 0) {
    return <h2>No products found in this category.</h2>;
  }

  return (
    <div className={CategoryProductsStyles.productContainer}>
      <h2 className={CategoryProductsStyles.title}>{ListingTitle} {category}</h2>
      <div className={CategoryProductsStyles.productList}>
        {filteredProducts.map((product) => (
          <div key={product.id} className={CategoryProductsStyles.productCard}>
            <ImageWithLoader src={product.categoryimage}
              alt={product.name}
              className={CategoryProductsStyles.productImage}/>
           
            <h3 className={CategoryProductsStyles.productName}>{product.name}</h3>
            <p className={CategoryProductsStyles.productDescription}>
              {product.categorydescription}
            </p>
            <p className={CategoryProductsStyles.productPrice}>Ksh {product.price}</p>
            <Link to={`/${product.link}`}>
              <button className={CategoryProductsStyles.productButton}>
                View Details
              </button>
            </Link>
          </div>
        )).slice(0, 4)}
      </div>
    </div>
  );
};

export default HomeProductsListing;
