import React from "react";
import Styles from "./slider.module.css";
import ImageWithLoader from "../hooks/ImageWithLoader";

const SliderTwo = () => {
  return (
    <div>
      <div className={Styles.imageContainer}>
      <ImageWithLoader src="/images/slider/slider1.jpg" alt="ASP" className={Styles.image} />
      
      </div>
    </div>
  );
};

export default SliderTwo;
