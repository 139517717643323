import React, { useState } from "react";
import styles from "./Payment.module.css";

const Payment = ({ onNext, total }) => {
  const [method, setMethod] = useState("");
  const [phone, setPhone] = useState("");
  const [cardDetails, setCardDetails] = useState({ number: "", cvv: "" });

  const handlePayment = () => {
    if (method === "MPESA" && phone.length === 10) {
      alert("MPESA Payment Verified!");
    } else if (method === "Credit Card" && cardDetails.number.length === 16 && cardDetails.cvv.length === 3) {
      alert("Credit Card Verified!");
    } else if (method === "Cash on Delivery") {
      alert("Cash on Delivery Selected!");
    } else {
      alert("Please complete payment details.");
      return;
    }
    onNext(method);
  };

  return (
    <div className={styles.paymentContainer}>
      <h2>Choose Payment Method</h2>
      <select onChange={(e) => setMethod(e.target.value)}>
        <option value="">Select Payment</option>
        <option value="MPESA">MPESA</option>
        <option value="Credit Card">Credit Card</option>
        <option value="Cash on Delivery">Cash on Delivery</option>
      </select>

      {method === "MPESA" && <input type="text" placeholder="Enter MPESA Number" onChange={(e) => setPhone(e.target.value)} />}
      {method === "Credit Card" && (
        <>
          <input type="text" placeholder="Card Number" onChange={(e) => setCardDetails({ ...cardDetails, number: e.target.value })} />
          <input type="text" placeholder="CVV" onChange={(e) => setCardDetails({ ...cardDetails, cvv: e.target.value })} />
        </>
      )}

      <button className={styles.payButton} onClick={handlePayment}>Pay Ksh {total}</button>
    </div>
  );
};

export default Payment;
