import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import CreativeDesignsData from "../alldata/creativedata";
import OfficeStationeryData from "../alldata/officestationerydata";
import PromotionalItemsData from "../alldata/promotionalitemsdata";
import SignageData from "../alldata/signagedata";
import Cart from "./Cart"; // Import Cart Component
import styles from "./ProductDetails.module.css";
import ImageGallery from "react-image-gallery";
import ImageWithLoader from "../hooks/ImageWithLoader";

const ProductDetails = () => {
  const { category, productLink } = useParams();
  const [allProducts, setAllProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState(() => {
    return JSON.parse(localStorage.getItem("cart")) || []; // Load cart from storage
  });
  const [showCart, setShowCart] = useState(false); // Control cart modal visibility

  useEffect(() => {
    const combinedProducts = [
      ...CreativeDesignsData,
      ...OfficeStationeryData,
      ...PromotionalItemsData,
      ...SignageData,
    ];
    setAllProducts(combinedProducts);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (allProducts.length > 0) {
      const matchedProduct = allProducts.find(
        (p) =>
          p.link.split("/")[0] === category &&
          p.link.split("/")[1] === productLink
      );
      setProduct(matchedProduct);
    }
  }, [category, productLink, allProducts]);

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value);
    if (newQuantity > 0) {
      setQuantity(newQuantity);
    }
  };

  const totalAmount = useMemo(() => {
    return product ? product.price * quantity : 0;
  }, [product, quantity]);

  const handleAddToCart = () => {
    if (!product) return;

    const newCart = [...cart];
    const existingItemIndex = newCart.findIndex(
      (item) => item.id === product.id
    );

    if (existingItemIndex !== -1) {
      newCart[existingItemIndex].quantity += quantity;
    } else {
      newCart.push({ ...product, quantity });
    }

    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart)); // Store in local storage
  };

  const openCart = () => setShowCart(true); // Function to open cart modal
  const closeCart = () => setShowCart(false); // Function to close cart modal

  // Calculate total number of items in the cart
  const totalItemsInCart = cart.reduce(
    (total, item) => total + item.quantity,
    0
  );

  if (!product) {
    return <h2>Product not found</h2>;
  }

  return (
    <div className={styles.productDetailsContainer}>
      <div className={styles.productInfo}>
        <div className={styles.gallery}>
          <h2>Checkout More Samples</h2>
          {product.productImages ? (
            <ImageGallery items={product.productImages} />
          ) : (
            ""
          )}
        </div>
        <div className={styles.productData}>
        <ImageWithLoader
            src={product.productImage}
            alt={product.name}
            className={styles.productImage} />
          
          <div className={styles.productText}>
            <h1 className={styles.productTitle}>{product.name}</h1>
            <p className={styles.productDescription}>
              {product.categorydescription}
            </p>
            <p className={styles.productPrice}>Price: Ksh {product.price}</p>
          </div>
        </div>
      </div>

      {/* Order Form */}
      <div className={styles.orderForm}>
        <h2>Order Details</h2>
        <table className={styles.orderTable}>
          <thead>
            <tr>
              <th>Item</th>
              <th>Image</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Price (Ksh)</th>
              <th>Total (Ksh)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{product.name}</td>
              <td>
              <ImageWithLoader src={product.productImages[0]}
                  alt={product.name}
                  className={styles.orderImage}  />
                
              </td>
              <td>{product.categorydescription}</td>
              <td>
                <input
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={handleQuantityChange}
                  className={styles.quantityInput}
                />
              </td>
              <td>{product.price}</td>
              <td>{totalAmount}</td>
            </tr>
          </tbody>
        </table>
        <button className={styles.addToCartButton} onClick={handleAddToCart}>
          Add to Cart
        </button>
        <button className={styles.viewCartButton} onClick={openCart}>
          View Cart ({totalItemsInCart}) {/* Display number of items in cart */}
        </button>
      </div>

      {/* Cart Modal */}
      {showCart && (
        <Cart cartItems={cart} setCart={setCart} onClose={closeCart} />
      )}
    </div>
  );
};

export default ProductDetails;
