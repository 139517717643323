const OfficeStationeryData = [
  {
    id: 151,
    name: "Business Cards",
    link: "officestationery/business-cards",
    category: "Office Stationery",
    categoryimage: "/images/categories/business-cards.jpg",
    categorydescription:
      "High-quality business cards to enhance your professional branding.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/businesscards/businesscard1.jpg",
        thumbnail: "/images/businesscards/businesscard1.jpg",
      },
      {
        original: "/images/businesscards/businesscard2.jpg",
        thumbnail: "/images/businesscards/businesscard2.jpg",
      },
      {
        original: "/images/businesscards/businesscard3.jpg",
        thumbnail: "/images/businesscards/businesscard3.jpg",
      },
      {
        original: "/images/businesscards/businesscard4.jpg",
        thumbnail: "/images/businesscards/businesscard4.jpg",
      },
      {
        original: "/images/businesscards/businesscard5.jpg",
        thumbnail: "/images/businesscards/businesscard5.jpg",
      },
      {
        original: "/images/businesscards/businesscard6.jpg",
        thumbnail: "/images/businesscards/businesscard6.jpg",
      },
      {
        original: "/images/businesscards/businesscard7.jpg",
        thumbnail: "/images/businesscards/businesscard7.jpg",
      },
      {
        original: "/images/businesscards/businesscard8.jpg",
        thumbnail: "images/businesscards/businesscard8.jpg",
      },
    ],
    currency: "Kshs",
    price: "5",
    quantity: 100,
    extradetails: "Available in standard, premium, and embossed finishes.",
    leadtimedelivery: 3,
  },
  {
    id: 152,
    name: "Letterheads",
    link: "officestationery/letterheads",
    category: "Office Stationery",
    categoryimage: "/images/categories/letterheads.jpg",
    categorydescription:
      "Custom-branded letterheads for official business correspondence.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 100,
    extradetails:
      "Printed on high-quality paper with your logo and company details.",
    leadtimedelivery: 3,
  },
  {
    id: 153,
    name: "Staff ID Cards",
    link: "officestationery/staff-id-cards",
    category: "Office Stationery",
    categoryimage: "/images/categories/staff-id-cards.jpg",
    categorydescription: "Professional staff ID cards for company employees.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails: "Includes plastic cards with lanyards and holders.",
    leadtimedelivery: 3,
  },
  {
    id: 154,
    name: "Receipt Books",
    link: "officestationery/receipt-books",
    category: "Office Stationery",
    categoryimage: "/images/categories/receipt-books.jpg",
    categorydescription:
      "Customized receipt books for your business transactions.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 50,
    extradetails: "Carbonized or non-carbonized, available in different sizes.",
    leadtimedelivery: 3,
  },
  {
    id: 155,
    name: "Event Cards",
    link: "officestationery/event-cards",
    category: "Office Stationery",
    categoryimage: "/images/categories/event-cards.jpg",
    categorydescription: "Beautifully designed event cards for any occasion.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 50,
    extradetails:
      "Custom wedding invitations, party invites, and corporate event cards.",
    leadtimedelivery: 3,
  },
  {
    id: 156,
    name: "Calendars",
    link: "officestationery/calendars",
    category: "Office Stationery",
    categoryimage: "/images/categories/calendars.jpg",
    categorydescription:
      "Custom-branded calendars for your business and clients.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 10,
    extradetails: "Wall, desk, and pocket calendars available.",
    leadtimedelivery: 3,
  },
  {
    id: 157,
    name: "Notebooks & Journals",
    link: "officestationery/notebooks-journals",
    category: "Office Stationery",
    categoryimage: "/images/categories/notebooks.jpg",
    categorydescription:
      "Personalized notebooks and journals for office use and giveaways.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 20,
    extradetails: "Hardcover and spiral-bound options available.",
    leadtimedelivery: 3,
  },
  {
    id: 158,
    name: "Envelopes",
    link: "officestationery/envelopes",
    category: "Office Stationery",
    categoryimage: "/images/categories/envelopes.jpg",
    categorydescription:
      "Branded envelopes for professional mailing and packaging.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 100,
    extradetails: "Available in different sizes and paper types.",
    leadtimedelivery: 3,
  },
  {
    id: 159,
    name: "Folders",
    link: "officestationery/folders",
    category: "Office Stationery",
    categoryimage: "/images/categories/folders.jpg",
    categorydescription:
      "Professional document folders for corporate presentations and storage.",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 50,
    extradetails: "Custom-printed with your company logo and details.",
    leadtimedelivery: 3,
  },
  {
    id: 160,
    name: "Stamps",
    link: "officestationery/stamps",
    category: "Office Stationery",
    categoryimage: "/images/categories/stamps.jpg",
    categorydescription: "Customized company stamps for official use.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 1,
    extradetails: "Self-inking and traditional stamp options available.",
    leadtimedelivery: 3,
  },
  {
    id: 161,
    name: "Wall Charts",
    link: "officestationery/wall-charts",
    category: "Office Stationery",
    categoryimage: "/images/categories/wall-charts.jpg",
    categorydescription:
      "Educational and informative wall charts for office spaces.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 5,
    extradetails:
      "Custom charts for business operations, schedules, and training materials.",
    leadtimedelivery: 3,
  },
  {
    id: 162,
    name: "Tear Sheets",
    link: "officestationery/tear-sheets",
    category: "Office Stationery",
    categoryimage: "/images/categories/tear-sheets.jpg",
    categorydescription: "Tear sheets for marketing and quick note-taking.",
    productImage: "/images/businesscards/businesscard8.jpg",
    productImages: [
      {
        original: "/images/logos/aspiregraphics.jpg",
        thumbnail: "/images/logos/aspiregraphics.jpg",
      },
      {
        original: "/images/logos/curdafrica.jpg",
        thumbnail: "/images/logos/curdafrica.jpg",
      },
      {
        original: "/images/logos/kpdclogo.jpg",
        thumbnail: "/images/logos/kpdclogo.jpg",
      },
      {
        original: "/images/logos/zurilogo.jpg",
        thumbnail: "/images/logos/zurilogo.jpg",
      },
    ],
    currency: "Kshs",
    price: "500",
    quantity: 100,
    extradetails:
      "Printed with your branding for promotions and client communications.",
    leadtimedelivery: 3,
  },
];

export default OfficeStationeryData;
