import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import CreativeDesignsData from "../alldata/creativedata";
import OfficeStationeryData from "../alldata/officestationerydata";
import PromotionalItemsData from "../alldata/promotionalitemsdata";
import SignageData from "../alldata/signagedata";
import CategoryProductsStyles from "./CategoryProducts.module.css";
import ImageWithLoader from "../hooks/ImageWithLoader";

// Combined all products into one object based on categories
const allProducts = {
  "creative-designs": CreativeDesignsData,
  "brand-identity": CreativeDesignsData,
  "office-stationery": OfficeStationeryData,
  "promotional-items": PromotionalItemsData,
  "adverts-signage": SignageData,
};

// Function to shuffle array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const CategoryProductsDisplay = () => {
  const { category } = useParams();
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (category && allProducts[category]) {
      const shuffledProducts = shuffleArray([...allProducts[category]]);
      setFilteredProducts(shuffledProducts);
    } else {
      setFilteredProducts([]);
    }
  }, [category]);

  if (filteredProducts.length === 0) {
    return <h2>No products found in this category.</h2>;
  }

  return (
    <div className={CategoryProductsStyles.productContainer}>
      <h2 className={CategoryProductsStyles.title}>Products in {category}</h2>
      <div className={CategoryProductsStyles.productList}>
        {filteredProducts.map((product) => (
          <div key={product.id} className={CategoryProductsStyles.productCard}>
            <ImageWithLoader
              src={product.categoryimage}
              alt={product.name}
              className={CategoryProductsStyles.productImage}
            />
            
            <h3 className={CategoryProductsStyles.productName}>
              {product.name}
            </h3>
            <p className={CategoryProductsStyles.productDescription}>
              {product.categorydescription}
            </p>
            <p className={CategoryProductsStyles.productPrice}>
              Ksh {product.price}
            </p>
            <Link to={`/${product.link}`}>
              <button className={CategoryProductsStyles.productButton}>
                View Details
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryProductsDisplay;
