import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./AllBlogPosts.module.css";
import blogData from "./blogData.json"; // Import JSON data
import ImageWithLoader from "../../hooks/ImageWithLoader";
const AllBlogPosts = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(blogData);
  }, []);

  return (
    <div className={styles.blogContainer}>
      <h1>Our Blog</h1>
      <div className={styles.blogGrid}>
        {blogs.map((blog, index) => (
          <div key={index} className={styles.blogCard}>
            <ImageWithLoader
              src={blog.image}
              alt={blog.title}
              className={styles.blogImage}
            />

            <h2>{blog.title}</h2>
            <p className={styles.category}>{blog.category}</p>
            <p className={styles.excerpt}>{blog.excerpt}</p>
            <p className={styles.author}>
              By {blog.author} | {blog.date}
            </p>
            <Link to={`/blog/${blog.slug}`} className={styles.readMore}>
              Read More
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllBlogPosts;
