import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserRegistration from "./UserRegistration";
import Payment from "./Payment";
import OrderConfirmation from "./OrderConfirmation";
import styles from "./Checkout.module.css";
import ImageWithLoader from "../hooks/ImageWithLoader";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems, totalCartPrice } = location.state || { cartItems: [], totalCartPrice: 0 };

  const [step, setStep] = useState(1); // Track checkout step
  const [userData, setUserData] = useState(null); // Store user details
  const [paymentMethod, setPaymentMethod] = useState(""); // Store selected payment method
  const [orderNumber, setOrderNumber] = useState(""); // Store Order No.

  // Proceed to next step
  const handleNextStep = (data) => {
    if (step === 1) {
      setUserData(data); // Save user registration details
      setStep(2);
    } else if (step === 2) {
      setPaymentMethod(data); // Save payment method
      setStep(3);
    } else if (step === 3) {
      setOrderNumber(`ORD-${Math.floor(Math.random() * 1000000)}`); // Generate order number
      setStep(4);
    }
  };

  return (
    <div className={styles.checkoutContainer}>
      {step === 1 && (
        <>
          <h2>Checkout</h2>
          {cartItems.length === 0 ? <p>No items in cart</p> : (
            <div>
              <ul className={styles.itemList}>
                {cartItems.map((item) => (
                  <li key={item.id} className={styles.item}>
                    <ImageWithLoader src={item.productImages[0]} alt={item.name} className={styles.image} />
                    
                    <div>
                      <p><strong>{item.name}</strong></p>
                      <p>Quantity: {item.quantity}</p>
                      <p>Price: Ksh {item.price}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <h3>Total: Ksh {totalCartPrice}</h3>
              <button className={styles.nextButton} onClick={() => setStep(2)}>Proceed to Registration</button>
            </div>
          )}
        </>
      )}

      {step === 2 && <UserRegistration onNext={handleNextStep} />}
      {step === 3 && <Payment onNext={handleNextStep} total={totalCartPrice} />}
      {step === 4 && <OrderConfirmation orderNumber={orderNumber} />}
    </div>
  );
};

export default Checkout;
